<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="INVOICE"
          :breadcrumb="[{ label: 'Retailer' }, { label: 'Invoice' }]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>
 

        <div class="content-body" style="margin-top: -50px">
          <!-- users list start -->
          <section>
            <div class="row" >
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div
                  class="card custom-card"
             
                >
                <div class="card-body">

                  <div class="row">
                      <div class="col text font">

                        <h4 class="text"><b>Invoices</b></h4>
                      </div>
                      <div class="col text-end cus-p-1">

                        <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                          class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                              icon="filter" /></strong></button>


                      </div>
                      <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1">

                        <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                          aria-label="Default select example">

                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>



                    <div class="row ">
                      <div class="d-md-none d-xl-none d-block col-3 text font cus-p-1">

                        <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                          aria-label="Default select example">
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>

                        </select>
                      </div>

                    </div>

                  <div class="row">




                    <div
                      class="col-md-12 col-xl-12 col-sm-12"
                   
                    >
                      <!-- <h3 class="text"><b>Invoices</b></h3> -->
                      <div class="container-fluid table-scroll">
                        <table class="table table-hover table-sm">
                          <tr
                            class="text tr-head table-wrapper-scroll-y rounded-circle"
                            style="background-color: white"
                          >
                            <th
                              class="text-truncate"
                              style="
                                background-color: #cdcdcd;
                                border-radius: 8px 0px 0px 8px;
                              "
                            >
                             Code
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Date
                            </th>
                            <th class="text-truncate" style="background-color: #cdcdcd">
                              Client Name
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Business
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Service
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Phone
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                              Invoice
                            </th>
                            <th style="background-color: #cdcdcd" class="text-truncate">
                            Due  Balance
                            </th>
                            <th
                              style="background-color: #cdcdcd"
                              class="text-truncate text-center"
                            >
                              Status
                            </th>
                            <th
                              style="
                                background-color: #cdcdcd;
                                border-radius: 0px 8px 8px 0px;
                              "
                              class="text-truncate"
                            >
                              Action
                            </th>
                          </tr>

                          <tbody class="text">
                            <tr v-for="(client_service, index) in clients" :key="index">
                              <td class="text-truncate">
                                <router-link
                                  @click="idStore(client_service.id)"
                                  :to="`/retailer/invoicerecipt/${client_service.id}`"
                                  style="color: #f21000"
                                >
                                  <strong> {{ client_service.clientServiceNo }}</strong>
                                </router-link>

                                <!-- <span v-if="client_service.client">{{
                                  client_service.id
                                }}</span> -->
                              </td>
                              <td class="text-truncate">
                                {{ dateTime(client_service.date) }}
                              </td>
                              <td class="text-truncate">
                                <span v-if="client_service.client">
                                  {{ client_service.client.fname }}
                                  {{ client_service.client.lname }}</span
                                >
                              </td>
                              <td class="text-truncate">
                                <span v-if="client_service.business">
                                  <!-- {{ client_service.service.name }} -->
                                  {{ client_service.business.name }}
                                </span>
                              </td>
                              <td class="text-truncate">
                                <span v-if="client_service.service">
                                  {{ client_service.service.name }}
                                </span>
                              </td>

                              <td class="text-truncate">
                                <span v-if="client_service.client">
                                  +91-{{ client_service.client.phone }}
                                </span>
                              </td>

                              <td class="text-truncate">
                                <span v-if="client_service">Rs.{{
                                  client_service.serviceCharge +
                                  client_service.serviceChargeGst
                                }}</span>
                              </td>
                              <td class="text-truncate">
                                <span v-if="client_service">Rs.{{
                                  getBallance(client_service)
                                }}</span>
                              </td>
                              <td v-if="client_service.client" class="text-truncate">
                                <p
                                  v-if="client_service.status == 'assigned'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'completed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 90px;
                                    background-color: #008000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <!-- <p
                                  v-if="client_service.status == 'pending'"
                                  class="text-white btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p> -->

                                <p
                                  v-if="client_service.status == 'pending'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #cdcdcd;
                                    color: white;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>New</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'processed'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #ffaa00;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'hold'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #8f8d8e;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'rejected'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #00364f;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'cancelled'"
                                  class="btn btn-sm"
                                  style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                                <p
                                  v-if="client_service.status == 'upgrade'"
                                  class="btn btn-sm"
                                  style="
                                    width: 60px;
                                    background-color: #ee0def;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 3px 3px 3px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  "
                                >
                                  <b>{{ client_service.status }}</b>
                                </p>
                              </td>

                              <td class="text-truncate text-end">
                                <div class="btn-group btn-group-sm" role="group">
                                  <router-link
                                    :to="`viewproject/${client_service.id}`"
                                    class="btn btns btn-sm text-white"
                                    style="
                                      padding: 5px 4.5px 5px 4.5px;
                                      background-color: #00364f;
                                    "
                                  >
                                    <font-awesome-icon icon="eye" />
                                  </router-link>

                                  <!-- <router-link
                                    :to="`/retailer/invoicerecipt/${client_service.id}`"
                                    class="btn btns btn-sm text-dark"
                                    style="
                                      padding: 5px 4.5px 5px 4.5px;
                                      background-color: yellow;
                                    "
                                  >
                                    <font-awesome-icon icon="file" />
                                  </router-link> -->
                                  <!-- <button
                                    @click="cancelProject(client_service.id)"
                                    v-if="client_service.status == 'pending'"
                                    class="btn btns btn-sm"
                                    style="
                                      padding: 5px 3px 5px 3px;
                                      background-color: #f21000;
                                      color: white;
                                    "
                                  >
                                    <b>
                                      <font-awesome-icon icon="remove" />
                                    </b>
                                  </button> -->
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- model  -->
  <!-- Button trigger modal -->



  
  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" novalidate onsubmit="return false">
          <div class="modal-header align-items-center mb-1" style="background-color: #00364f">
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white">
              <span class="todo-item-favorite cursor-pointer me-75 text-white"><i data-feather="star"
                  class="font-medium-2"></i></span>
              <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal" stroke-width="3">X</i>
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="clientServiceNo" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="clientServiceNo">
                  <label class="form-check-label" for="clientServiceNo">
                    Code
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="date" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="date">
                  <label class="form-check-label" for="date">
                    Date
                  </label>
                </div>
              </li>
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="dueDate" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="dueDate">
                  <label class="form-check-label" for="dueDate">
                    Due Date
                  </label>
                </div>
              </li> -->
              <!-- <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="completionDate" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="completionDate">
                  <label class="form-check-label" for="completionDate">
                    Completion Date
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="client_id" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="client_id">
                  <label class="form-check-label" for="client_id">
                    Client PanNo
                  </label>
                </div>
              </li> -->

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="status" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="status">
                  <label class="form-check-label" for="status">
                    Status
                  </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="service_id" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="service_id">
                  <label class="form-check-label" for="service_id">
                    Service
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="all">
                  <label class="form-check-label" for="all">
                    All
                  </label>
                </div>
              </li>
            </ul>


            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'"
                class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1"
                placeholder="Enter Value">
              <input type="text" v-if="filterType == 'clientServiceNo'" class="form-control" v-model="filterValue"
                id="exampleFormControlInput1" placeholder="Enter Value">
              <input type="text" v-if="filterType == 'client_id'" @change="findRecordeByPanId" class="form-control"
                v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">




              <select v-model="filterValue" v-if="filterType == 'status'" class="form-select"
                aria-label="Default select example">
                <option selected disabled>Please Select...</option>
                <option value="pending">New</option>
                <option value="assigned">Assign</option>
                <option value="completed">Complete</option>
                <option value="cancelled">Cancelled</option>
                <option value="accepted">Accepted</option>
                <option value="rejected">Rejected</option>
                <option value="upgrade">Upgrade</option>
                <option value="processed">Processed</option>

              </select>


              <select v-model="filterValue" v-if="filterType == 'service_id'" class="form-select"
                aria-label="Default select example">
                <option selected disabled>Please Select...</option>
                <option v-for="(service, index) in services" :key="index" :value="service.id">{{ service.name }}</option>


              </select>

            </div>

            <button v-if="filterType != 'client_id'" @click="loadClients()" type="button"
              class="btn btn-success btn-sm">Success</button>




          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->
</template>

<script>
import moment from "moment";
import Banner from "../../../components/retailer/comman/Banner.vue";
import Spinner from "../../../components/retailer/comman/Spinner.vue";
// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
  name: "Project",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue: '',
      filterType: '',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,
      loading: false,
      services: [],
      clients: [],
    };
  },
  methods: {
    getBallance(client_service) {
      var bal = 0;
      var payments = client_service.client_payment;
      for (var i = 0; i < payments.length; i++) {
        bal += payments[i].amt;
      }
      bal = client_service.serviceCharge + client_service.serviceChargeGst - bal;
      return bal;
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    statusNoData(status) {
      // allrecords;
      let num = 0;
      for (var i = 0; i < this.clients.length; i++) {
        if (this.clients[i].status == status) {
          num++;
        }
        // if (this.clients[i].status == "cancelled") {
        //   num++;
        // }
      }
      return num;
    },
    cancelProject(id) {
      this.$swal
        .fire({
          title: "Are you sure For Cancel ?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.loading = true;
            this.$axios
              .post(
                `retailer/cancelclientservice`,
                { client_service_id: id },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.accessToken,
                  },
                }
              )
              .then((res) => {
                res.data;
                // console.log(res.data.data)
                this.$swal.fire({
                  position: "top-end",
                  icon: "success",
                  title: "The Project Cancelled successfully.",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.loadClients();
              })
              .finally(() => (this.loading = false));
          }
        });
    },
    findRecordeByPanId(e) {

this.$axios
  .get(`retailer/client?pan=${e.target.value}`, {
    headers: { Authorization: "Bearer " + localStorage.accessToken },
  })
  .then((res) => {

    if (res.data.data.data[0]) {
      this.filterValue = res.data.data.data[0].id


      this.loadClients()
    }

  });
},
servicesLoad() {

this.$axios
  .post(`allservice`, {}, {
    headers: { Authorization: "Bearer " + localStorage.accessToken },
  })
  .then((res) => {
    this.services = res.data.data
    console.log(res.data.data)

  });
},


    valueNullFilter() {
      this.filterValue = "";
    },
    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }



      if (pg == null) {






        if (this.filterType == 'all') {
          this.filterValue = ''
          this.filterType = ''
        }



        if (this.filterValue != "" && this.filterType != "") {

          pageUrl += `retailer/clientservice?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;
        } else {
          pageUrl += `retailer/clientservice?per_page=${this.per_page}`;
        }

      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clients = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    dateTime(value) {
      console.log(value);
      return moment(value).format("DD-MM-YYYY");
    },
    idStore(id) {
      var Invoice = {};
      // itr = JSON.parse(localStorage.getItem('in'))
      Invoice.id = id;
      localStorage.setItem("Invoice", JSON.stringify(Invoice));
    },
    loadPageTitle() {
      document.title = this.$store.state.retailerPageTitles.Invoice
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.servicesLoad()
             
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
 
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -20px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}




.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}





.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
